@import '../styles/colors.scss';
.resetButton{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}

.resetButton button{
    background: $color-dark;
    color: white;
    border: 1px solid;
    padding: 5px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .1s;
    font-family: 'spaceMono';
    font-size: 15px;
}

.resetButton button:hover{
  transform: scale(1.03)
}