
@import '../styles/colors.scss';
.container{
  border: 1px solid;
  margin-bottom: 10px;
}

.container ul{
  list-style-type: none;
  text-align: left;
  padding-left: 0;
}

.container ul li{
  margin-bottom: 5px;
  cursor: pointer;
}

.active{
  box-sizing: border-box;
  border: 2px solid $color-sunrise;
  background-color: $shade-sunrise100;
}
.coutryActive{
  box-sizing: border-box;
  border: 2px solid $color-sunlight;
  background-color: $shade-sunlight100;
}

.searchActive{
  box-sizing: border-box;
  border: 2px solid $color-success;
  background-color: #4bb54352;
}