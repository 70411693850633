@import '../styles/colors.scss';

.container{
  width: 30vw;
  border: 1px solid;
  border-left: none;
  height: calc(100vh - 77px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
}

.top{
  margin-bottom: 40px;
  padding: 0 10px;
}

.downloadLink{
  text-decoration: none;
  font-weight: bold;
}

.downloadSearch{
  color: #308c30;
}

.downloadGlobal{
  color: $color-sunrise;
}

.donwloadCountry{
  color: $color-sunlight;
}

.downloadLink:hover{
  text-decoration: underline;
}

.container .bottom{
  height: 100%;
  max-height: 80%;
  overflow: scroll;
  margin-bottom: 20px;
  position: relative;
}

.loader{
  color: $color-sunrise;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  animation: pulsating 2s infinite;
}

@keyframes pulsating {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}