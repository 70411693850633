$title-font-family: 'spaceMono';
$emphase-font-family: 'FKRasterGroteskSharp';
$body-font-family: 'FKDisplayRegular';

$title-font-size-xl: 154px;
$title-font-size-lg: 103px;
$title-font-size-md: 51px;
$title-font-size-sm: 25px;

$emphase-font-size-xl: 129px;
$emphase-font-size-lg: 86px;
$emphase-font-size-md: 43px;
$emphase-font-size-sm: 21px;

$body-font-size-lg: 51px;
$body-font-size-md: 36px;
$body-font-size-sm: 18px;

$body-font-line-height: 150%;


$label-font-size-md: 18px;
$label-font-size-sm: 13px;


$font-weight-normal: 400;
