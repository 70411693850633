@import '../styles/colors.scss';

.searchParentContainer{
  position: relative;
}
.searchContainer{
  display: flex;
  padding: 10px;
  border: 1px solid $color-body;
  align-items: center;
  position: relative;
}
.searchMainContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

input{
  padding: 5px;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0px;
  margin-left: 10px;
  width: 300px;
}

input:focus{
  outline: none;
}
.selectWrapper{
  position: relative;
  select{
    border: 1px solid;
    height: 30px;
    width: 140px;
    -webkit-appearance: none;
    appearance: none;
    padding: 0px 15px 1px 15px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .1s;
    font-family: 'spaceMono';
    font-size: 15px;
    color: white;
    text-align: center;
    cursor: default;
    background-color: inherit;
    border: 1px solid #b7b7b7;
    color: #b7b7b7;
  }
  // &:after {
  //   content: "↓";
  //   font-size: 1rem;
  //   top: 6px;
  //   right: 10px;
  //   position: absolute;
  // }
}
// select:has(option:disabled:checked) {
//   color: gray;
// }

select:focus{
  border: none;
}

.searchDeleteContainer{
  position: relative;
}

.searchDeleteContainer button{
  position: absolute;
  right: 5px;
  color: red;
  opacity: .7;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  top: 1px;
}

.searchDeleteContainer button i:before{
  padding-top: 3px;
  display: block;
}

.searchButton{
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid $color-dark;
  background-color: $color-dark;
  color: white;
  padding: 0px 15px 1px 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .1s;
  font-family: 'spaceMono';
  font-size: 15px;
  color: white;
  text-align: center;
  height: 30px;
}

button[disabled]{
  cursor: default;
  background-color: inherit;
  border: 1px solid #b7b7b7;
  color: #b7b7b7;
}

.requestState{
  color: #308c30;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.requestState span{
  color: red;
}

.focus{
  box-sizing: border-box;
  .selectWrapper{
    position: relative;
    select{
      background: white;
      color: $color-sunset;
      border: 1px solid;
      border: 1px solid $color-sunset;
      cursor: pointer;
      &:focus{
        outline: none;
      }
    }
  }
}

.cleanSearch{
  background-color: transparent;
  position: absolute;
  left: 0;
  bottom: -40px
}

.cleanSearch button{
  margin-left: 10px;
  background: none;
  border: 1px solid;
  text-transform: uppercase;
  cursor: pointer;
  border-color: red;
  background-color: red;
  color: white;
  font-family: 'spaceMono';
  padding: 0px 15px 1px 15px;
  height: 30px;
  width: 140px;
  font-size: 14px;
}