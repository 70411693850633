@charset "UTF-8";

@font-face {
  font-family: "aif-icons";
  src:url("../fonts/aif-icons.eot");
  src:url("../fonts/aif-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/aif-icons.woff") format("woff"),
    url("../fonts/aif-icons.ttf") format("truetype"),
    url("../fonts/aif-icons.svg#aif-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "aif-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "aif-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-about:before {
  content: "\64";
}
.icon-arrow:before {
  content: "\65";
}
.icon-lens:before {
  content: "\66";
}
.icon-news:before {
  content: "\67";
}
.icon-newsletter:before {
  content: "\68";
}
.icon-newsletter-1:before {
  content: "\61";
}
.icon-finger-icon:before {
  content: "\62";
}
