@import '../styles/colors.scss';
.container{
  position: relative;
}
.container_list{
  list-style: none;
  padding: 10px;
  margin-top: 0;
  padding-top: 0;
}
.container_list_item{
  padding: 10px;
}

.container_list_item > div{
  padding: 10px;
  margin: 0;
  border-radius: 3px;
}
.showmoreButton{
  margin-bottom: 20px;
  background: $color-dark;
  color: white;
  border: 1px solid;
  padding: 5px 20px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: 'spaceMono';
  font-size: 15px;
}

.noresults{
  color: red;
  margin-top: 30%;
  font-weight: bold;
}