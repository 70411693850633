.containerTitle{
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
}

.accordionContent ul {
  columns: 6;
  -webkit-columns: 6;
  -moz-columns: 6;
}