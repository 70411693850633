.calendarContainer{
  display: flex;
  flex-direction: column;
}
.calendarContainer h3{
  color: black;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin: 0;
  margin-bottom: 10px;
}
.dateRange{
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
}