.container{
  position: absolute;
  display: flex;
  left: 20px;
  flex-direction: column-reverse;
  top: 50%;
}
.container button{
  background: white;
  border: thin solid gray;
  border-radius: 3px;
  cursor: pointer;
  margin: 5px;
  font-size: 20px;
  height: 30px;
  width: 30px;
  padding: 0;
}