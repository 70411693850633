.app {
  text-align: center;
}

.aiflogo{
height: 30px;
position: absolute;
left: 40px;
opacity: .4;
bottom: 40px;
}
.appHeader h2{
  margin: 0;
}
.maincontainer {
  display: flex;
  flex-direction: row;
}
.datesearchContainer{
  display: flex;
  justify-content: space-between;
  padding: 25px;
  height: 110px;
  box-sizing: border-box;
}
.mapcontainer{
  border: 1px solid;
  width: 70vw;
  height: calc(100vh - 77px);
  overflow: hidden;
  position: relative;
}

.mapcontainer svg{
  height:100%;
  width: 100%;
}

.mapcontainer svg path:focus{
  outline: none;
}

/* .mapcontainer svg path:focus{
  fill: red !important;
} */
